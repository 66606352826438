<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_74.png" alt="" />
      <span>{{ menuTitle }}</span>
    </div>
    <div class="left_body">
      <ul class="menu">
        <li v-for="item in items" :key="item.name">
          <router-link
            :to="item.link"
            :class="{ on: isActive(item.link) }"
            @click="refreshPage(item.link)"
            >{{ item.name }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    menuTitle() {
      // 현재 경로에 따라 메뉴명을 설정
      if (this.$route.path.includes("/projects")) {
        return "프로젝트관리"; // 프로젝트 관련 경로일 때
      } else if (this.$route.path.includes("/orders")) {
        return "수주관리"; // 수주 관련 경로일 때
      } else if (this.$route.path.includes("/accounting")) {
        return "정산관리"; // 정산 관련 경로일 때
      } else if (this.$route.path.includes("/managers")) {
        return "담당자관리"; // 담당자 관련 경로일 때
      } else if (this.$route.path.includes("/notices")) {
        if (this.userType === "M") {
          return "공지사항 관리";
        } else if (this.userType === "C") {
          return "공지사항";
        } else {
          return "공지사항";
        }
      } else {
        return "내 정보 관리"; // 기본 메뉴명
      }
    },
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    companyid() {
      return this.$store.getters.getcompanyid;
    },
  },
  methods: {
    refreshPage(link) {
      if (this.$route.path === link) {
        // 동일한 경로로 클릭했을 때는 페이지를 새로고침
        const randomString = new Date().getTime();
        this.$router.replace({ path: link, query: { reload: randomString } });
      }
    },
    isActive(link) {
      // 프로젝트 목록, 프로젝트 등록, 프로젝트 상세 페이지에서 프로젝트 활성화되도록 처리
      const currentPath = this.$route.path;

      if (this.$route.path.includes("/projects")) {
        if (this.$route.path.includes("/projects/company")) {
          return (
            currentPath.startsWith("/projects/companylist") ||
            (currentPath.startsWith("/projects/companyview") && // 프로젝트상세(업체) 페이지
              link === "/projects/companylist")
          );
        } else {
          return (
            currentPath.startsWith("/projects/list") || // 프로젝트목록 페이지
            currentPath.startsWith("/projects/add") || // 프로젝트등록 페이지
            (currentPath.startsWith("/projects/view") && // 프로젝트상세(업체) 페이지
              link === "/projects/list")
          );
        }
      }

      if (this.$route.path.includes("/managers")) {
        return (
          currentPath.startsWith("/managers/list") || // 담당자목록 페이지
          currentPath.startsWith("/managers/add") || // 담당자등록 페이지
          (currentPath.startsWith("/managers/view") && // 담당자상세 페이지
            link === "/managers/list")
        );
      }

      if (this.$route.path.includes("/notices")) {
        return (
          currentPath.startsWith("/notices/list") || // 공지사항목록 페이지
          currentPath.startsWith("/notices/add") || // 공지사항등록 페이지
          currentPath.startsWith("/notices/view") || // 공지사항등록 페이지
          (currentPath.startsWith("/notices/companyview") && // 공지사항상세 페이지
            link === "/notices/list")
        );
      }

      if (this.$route.path.includes("/accounting")) {
        return (
          //currentPath.startsWith("/accounting/list") || // 정산목록 페이지
          currentPath.startsWith("/accounting/list") && //
          link === "/accounting/list"
        );
      }

      // 수주 관련 경로 처리
      if (
        currentPath.startsWith("/orders/list") ||
        currentPath.startsWith("/orders/view")
      ) {
        return link === "/orders/list";
      }

      // 나의 수주내역 처리
      if (currentPath.startsWith("/orders/self")) {
        return link === "/orders/self";
      }

      if (this.$route.path.includes("/myinfo")) {
        return (
          currentPath.startsWith("/myinfo/view") && link === "/myinfo/view"
        );
      }
    },
  },
};
</script>

<style scoped></style>
