<template>
  <div class="container_login">
    <form @submit.prevent="login">
      <div class="wrap">
        <img src="@/images/logo.png" alt="Logo" class="logo" />
        <div class="logotxt">마이즈 클라우드 파트너 솔루션</div>
        <input
          type="text"
          v-model="userid"
          id="userid"
          ref="userid"
          placeholder="아이디"
          TabIndex="1"
          autofocus
        />
        <input
          type="password"
          v-model="password"
          id="password"
          placeholder="비밀번호"
          ref="password"
          TabIndex="2"
        />
        <div class="id_wrap">
          <div style="display: none">
            <input
              id="chkLogin"
              type="checkbox"
              v-model="autoLogin"
              name="chkLogin"
            /><label for="chkLogin">자동로그인</label>
          </div>
        </div>
        <button type="submit">로그인</button>
        <div class="line"></div>
        <span style="font-weight: 300; letter-spacing: 0.6em; font-size: 0.7em"
          >MizeCloud Partner</span
        >
        <div class="bottom"></div>
      </div>
      <div class="footer_tl">MizeCloud</div>
      <div class="footer_tr">
        <a href="http://mizeinc.co.kr/index.html" target="_blank"
          >Help | <img src="@/images/q_color.png"
        /></a>
      </div>
      <div class="footer_l">
        <a href="https://mizeinc.co.kr/introduce.html" target="_blank"
          ><img src="@/images/logo.png"
        /></a>
      </div>
      <div class="footer">Copyright ⓒ Mize Inc. All Rights Reserved.</div>
    </form>
  </div>
</template>

<script>
import apiClient from "@/apiClient";
import { useToast } from "vue-toastification";

export default {
  name: "LoginPage",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      userid: "",
      password: "",
      autoLogin: false, // 자동 로그인 상태를 저장할 변수
    };
  },
  methods: {
    async login() {
      if (!this.userid) {
        this.$refs.userid.focus();
        this.toast.error("아이디를 입력해주세요.", {
          position: "bottom-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (!this.password) {
        this.$refs.password.focus();
        this.toast.error("비밀번호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      const user = {
        UserId: this.userid,
        Password: this.password,
      };

      try {
        const response = await apiClient.post("/api/Auth/login", user, {
          withCredentials: true,
        });

        if (response.status === 200) {
          //const accessToken = response.data.user.AccessToken;

          // 자동로그인 체크박스 상태에 따라 로컬스토리지에 토큰 저장
          // if (this.autoLogin) {
          //   localStorage.setItem("accessToken", accessToken);
          //   localStorage.setItem("autoLogin", true);
          // } else {
          //   localStorage.removeItem("autoLogin");
          // }

          localStorage.setItem("userid", this.userid);
          localStorage.setItem("usertype", response.data.user.UserType);
          localStorage.setItem("companyid", response.data.user.CompanyId);
          localStorage.setItem(
            "userpermission",
            response.data.user.UserPermission
          );

          this.$router.replace({ path: "/notices/list" });
          //this.$router.push({ path: "/notices/list" });

          // if (response.data.user.UserType === "C") {
          //   this.$router.push({ path: "/projects/companylist" });
          // } else {
          //   this.$router.push({ path: "/projects/list" });
          // }
        }
      } catch (error) {
        if (error.response) {
          // 요청이 서버에 도달했으나 응답을 받지 못한 경우
          console.error(`Error Status: ${error.response.status}`);
          console.error(`Error Message: ${error.response.data.message}`);
          this.toast.error(`${error.response.data.message}`, {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        } else if (error.request) {
          // 요청이 서버에 도달하지 못한 경우
          console.error(`Request Error: ${error.request}`);
          this.toast.error(`${error.request}`, {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        } else {
          console.error(`Error: ${error.message}`);
          this.toast.error(`${error.message}`, {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },

    logout() {
      localStorage.clear();
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    console.log("mounted 진입");
    // 만약 localStorage에 userid가 존재한다면 메인 페이지로 리다이렉트
    if (this.$route.path === "/login") {
      const userid = localStorage.getItem("userid");
      if (userid) {
        this.$router.push({ path: "/notices/list" }); // 메인 페이지로 이동 (원하는 경로로 수정 가능)
      }
    }
    // else {
    //   this.chkAutoLogin(); // 자동 로그인 여부 확인
    // }
    // this.chkAutoLogin();
  },
};
</script>

<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
