<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">정산목록</div>
        <div class="description">
          정산 목록을 조회할 수 있습니다.
          <div v-if="userType === 'M'">
            (계산서발급완료 처리가 된 프로젝트 기준으로 확인 가능합니다.)
          </div>
        </div>
        <article class="right_body">
          <div class="acctopbox">
            <div class="flexcolumn">
              <button @click="prevPageGroup2">≪</button>
              <button @click="prevPage2">＜</button>
              <span class="date">{{ formattedCurrentMonth }}</span>
              <button @click="nextPage2">＞</button>
              <button @click="nextPageGroup2">≫</button>
            </div>
            <div class="flexcolumn">
              <div>정산예정금액:</div>
              <div class="total">
                {{ formattedSelectedAmount }}
              </div>
              <div>원</div>
              <div class="vat">(VAT별도)</div>
            </div>
          </div>
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'M'">
                  <th>수주업체별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>상태별</th>
                  <td>
                    <div class="inner_td">
                      <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C') -->
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'B' }"
                        @click="updateStatus('B')"
                      >
                        계산서발급완료
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'E' }"
                        @click="updateStatus('E')"
                      >
                        정산완료
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="userType === 'M'">
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="projectName">프로젝트명</option>
                        <option value="companyName">수주업체</option>
                        <option value="pmName">PM담당자</option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalAccountings }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>건씩</span>
            </div>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="accountingList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col v-if="userType === 'M'" style="width: 5%" />
                  <col style="width: 5%" />
                  <col />
                  <col style="width: 10%" />
                  <col style="width: 15%" />
                  <col style="width: 14%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                  <col style="width: 8%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th v-if="userType === 'M'">
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">프로젝트명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">수주업체</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">계산서발급일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">금액 (vat별도)</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>PM담당자</th>
                    <th>수주담당자</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="accounting in paginatedAccountings"
                    :key="accounting.Id"
                  >
                    <td v-if="userType === 'M'" class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(accounting.id)"
                        :checked="selectedAccountings.includes(accounting.id)"
                      />
                    </td>
                    <td class="no_mobile">{{ accounting.no }}</td>
                    <td class="detail_page_a o2 o_title">
                      <div>
                        <router-link
                          v-if="userType === 'M'"
                          :to="{
                            name: 'ProjectView',
                            params: { id: accounting.id },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ accounting.projectName }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                        <router-link
                          v-if="userType === 'C'"
                          :to="{
                            name: 'ProjectCompanyView',
                            params: { id: accounting.id },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ accounting.projectName }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="name o5r">
                      <span class="only_mobile">수주업체 : </span>
                      <span>{{
                        accounting.choiceCompanyName
                          ? accounting.choiceCompanyName
                          : "미정"
                      }}</span>
                    </td>
                    <td class="name o5">
                      <span class="only_mobile">계산서발급일 : </span>
                      <span>
                        {{ accounting.billDate }}
                      </span>
                    </td>
                    <td class="seat o4">
                      <span class="only_mobile">금액 : </span>
                      <span>{{ accounting.price }} 원</span>
                      <span
                        v-if="accounting.addPrice && accounting.addPrice != '0'"
                      >
                        {{
                          accounting.addPrice.includes("-")
                            ? "( " + accounting.addPrice + " )"
                            : "( +" + accounting.addPrice + " )"
                        }}
                      </span>
                    </td>
                    <td class="phone o6r">
                      <span class="only_mobile">PM담당자 : </span>
                      <span>{{
                        accounting.mizePMUserName
                          ? accounting.mizePMUserName
                          : "미지정"
                      }}</span>
                    </td>
                    <td class="phone o6">
                      <span class="only_mobile">수주담당자 : </span>
                      <span>{{
                        accounting.choiceUserName
                          ? accounting.choiceUserName
                          : "미정"
                      }}</span>
                    </td>
                    <td class="center_td o_stat">
                      <div :class="getStatusClass(accounting.mizeStatus)">
                        <span>{{ getStatusText(accounting.mizeStatus) }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col />
                  <col style="width: 12%" />
                  <col style="width: 13%" />
                  <col style="width: 14%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">프로젝트명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">수주업체</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">계산서발급일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">금액</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>PM담당자</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="10">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div v-if="userType === 'M'">
              <a @click.prevent="accountFinishSelected">정산완료</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "AccountingList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const currentDate = new Date();
    return {
      accountingList: [], // 사업장 목록 데이터를 저장할 배열
      selectedAccountings: [], // 선택된 사업장들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "projectName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalAccountings: 0, // 총  수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
      totalPages2: 12,
      currentYear: currentDate.getFullYear(), // 현재 연도
      currentMonth: currentDate.getMonth(), // 현재 월 (0이 1월이므로 0-based)
      selectedAmount: 0,
    };
  },
  computed: {
    formattedSelectedAmount() {
      // 선택된 항목들의 금액 합산
      const totalAmount = (
        this.userType === "C" ? this.accountingList : this.selectedAccountings
      ).reduce((total, accountingItem) => {
        const accounting = this.accountingList.find(
          (a) => a.id === (accountingItem.id || accountingItem)
        );
        if (!accounting) {
          return total;
        }
        // 콤마가 포함된 문자열을 숫자로 변환
        const price = parseFloat(accounting.price.replace(/,/g, "")) || 0;
        const addPrice = parseFloat(accounting.addPrice.replace(/,/g, "")) || 0;

        // 총 금액 계산
        return total + price + addPrice;
      }, 0);

      // 합산된 금액을 천 단위 콤마를 추가한 문자열로 변환
      return totalAmount.toLocaleString();
    },
    formattedCurrentMonth() {
      return this.getFormattedMonth(this.currentMonth, this.currentYear);
    },
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    companyid() {
      return this.$store.getters.getcompanyid;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedAccountings() {
      if (!Array.isArray(this.accountingList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.accountingList);
      console.log(
        "Paginated accountingList: ",
        this.accountingList.slice(start, end)
      );
      return this.accountingList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalAccountings / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    prevPage2() {
      if (this.currentMonth > 0) {
        this.currentMonth--;
      } else if (this.currentYear > 2020) {
        // 2020년 이하로는 가정하지 않음
        this.currentYear--;
        this.currentMonth = 11; // 12월로 이동
      }

      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    nextPage2() {
      if (this.currentMonth < 11) {
        this.currentMonth++;
      } else {
        this.currentYear++;
        this.currentMonth = 0; // 1월로 이동
      }

      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup2() {
      if (this.currentMonth - 5 >= 0) {
        this.currentMonth -= 5;
      } else {
        this.currentMonth = (12 + this.currentMonth - 5) % 12;
        if (this.currentYear > 2020) {
          // 2020년 이하로는 가정하지 않음
          this.currentYear--;
        }
      }

      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    nextPageGroup2() {
      if (this.currentMonth + 5 <= 11) {
        this.currentMonth += 5;
      } else {
        this.currentMonth = (this.currentMonth + 5) % 12;
        this.currentYear++;
      }

      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    getFormattedStartDate(startDate) {
      if (!startDate) {
        return "";
      }

      // 날짜 문자열을 '^'로 분리
      const dates = startDate.split("^");

      // 첫 번째 날짜는 그대로 표시하고, 나머지 날짜가 있다면 "(선택가능)" 추가
      if (dates.length > 1) {
        return `${dates[0]} (선택가능)`;
      }

      return dates[0]; // 첫 번째 날짜만 반환
    },
    // <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C') -->
    // 상태에 따른 문구 반환
    getStatusText(status) {
      console.log("status---1---", status);
      switch (status) {
        case "N":
          return "수주전";
        case "Y":
          return "수주완료";
        case "YY":
          return "프로젝트완료";
        case "B":
          return "계산서발급완료";
        case "F":
          return "수주철회";
        case "C":
          return "프로젝트취소";
        case "E":
          return "정산완료";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(status) {
      console.log("status---2---", status);
      switch (status) {
        case "N":
          return "stat back_green"; // 수주전
        case "Y":
          return "stat back_blue"; // 수주완료
        case "YY":
          return "stat back_grey"; // 프로젝트완료
        case "B":
          return "stat back_red"; // 계산서발급완료
        case "F":
          return "stat back_orange"; // 수주철회
        case "C":
          return "stat back_lightgrey"; // 프로젝트취소
        case "E":
          return "stat back_purple"; // 정산완료
        default:
          return "stat back_default"; // 기본 값
      }
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },

    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedAccountings = this.accountingList.map(
          (accounting) => accounting.id
        );
      } else {
        this.selectedAccountings = [];
      }
    },
    toggleSelection(accountingId) {
      const index = this.selectedAccountings.indexOf(accountingId);

      if (index > -1) {
        this.selectedAccountings.splice(index, 1);
      } else {
        this.selectedAccountings.push(accountingId); // 기존 코드를 이 줄로 수정
      }
    },
    getPriceById(id) {
      const accounting = this.accountingList.find((a) => a.id === id);

      if (!accounting) {
        return "0";
      }

      // 콤마가 있는 문자열을 숫자로 변환
      const price = parseFloat(accounting.price.replace(/,/g, "")) || 0;
      const addPrice = parseFloat(accounting.addPrice.replace(/,/g, "")) || 0;

      // 금액 계산 (addPrice가 음수일 수도 있으므로 더하기)
      const totalPrice = price + addPrice;

      // 숫자를 천 단위로 콤마를 추가한 문자열로 변환
      const formattedTotalPrice = totalPrice.toLocaleString();

      return formattedTotalPrice;
    },
    accountFinishSelected() {
      if (this.selectedAccountings.length === 0) {
        this.toast.error("원하시는 정산완료 처리 할 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      // 이미 정산완료된 항목 확인
      const alreadyCompleted = this.selectedAccountings.some((id) => {
        const accounting = this.accountingList.find((a) => a.id === id);
        return (
          accounting && this.getStatusText(accounting.mizeStatus) === "정산완료"
        );
      });

      if (alreadyCompleted) {
        this.toast.error("이미 정산완료 처리된 항목이 있습니다.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
        return;
      }

      if (confirm("선택된 프로젝트 항목을 정산완료 처리하시겠습니까?")) {
        this.AccountingFinish(this.selectedAccountings);
      }
    },
    async AccountingFinish(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Accounting/accounting-finish", {
            Id: id,
            Status: "E",
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to finish accounting for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedAccountings = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error finish accounting:", error);
      }
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.accountingList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.accountingList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    // 현재 페이지 번호 포맷팅
    getFormattedMonth(monthIndex, year) {
      const month = (monthIndex + 1).toString().padStart(2, "0");
      return `${year}.${month}`;
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.accountingList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.accountingList = []; // 회의실 룸 목록 초기화
      this.fetchData();
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";

        if (this.userType === "M") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
        } else {
          v_companyId = this.companyid;
        }
        //alert(v_companyId);
        // alert(this.getFormattedMonth(this.currentMonth, this.currentYear));

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          LoginUserId: localStorage.getItem("userid"),
          SearchYearMonth: this.getFormattedMonth(
            this.currentMonth,
            this.currentYear
          ),
        };

        let response;

        // userType에 따른 다른 API 호출
        if (this.userType === "M") {
          // 'M'일 때 호출하는 API
          response = await apiClient.post(
            "/api/Accounting/all-accounting-mize-list", // 'M'의 엔드포인트
            searchParam
          );
        } else if (this.userType === "C") {
          // 'C'일 때 호출하는 API
          response = await apiClient.post(
            "/api/Accounting/all-accounting-partner-list", // 'C'의 엔드포인트 (예시)
            searchParam
          );
        }

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.accountingList = Array.isArray(data.data) ? data.data : [];
          this.totalAccountings = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching accounting list data:", error);
      }
    },
  },
  mounted() {
    this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchData();

    // userType이 'C'인 경우, 모든 항목 선택
    if (this.userType === "C") {
      this.selectedAccountings = this.accountingList.map((a) => a.id);
    }
  },
};
</script>
