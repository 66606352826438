<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">프로젝트 상세보기</div>
        <div class="description">
          프로젝트건을 조회할 수 있습니다. <span class="text_red"></span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트상태</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상태</th>
                  <td>
                    <div :class="getStatusClass(this.mizeStatus)">
                      <span>{{ getStatusText(this.mizeStatus) }}</span>
                    </div>
                  </td>
                </tr>
                <tr v-if="mizeStatus === 'F'">
                  <th>철회 일시</th>
                  <td>{{ withDrawalDtm }}</td>
                </tr>
                <tr v-if="mizeStatus === 'F'">
                  <th>철회 사유</th>
                  <td>{{ withDrawalMemo }}</td>
                </tr>
                <tr v-if="mizeStatus === 'C'">
                  <th>취소 일시</th>
                  <td>{{ cancelDtm }}</td>
                </tr>
                <tr v-if="mizeStatus === 'C'">
                  <th>취소 사유</th>
                  <td>{{ cancelMemo }}</td>
                </tr>
              </tbody>
            </table>
            <div class="part_title">프로젝트정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트명<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="프로젝트명을 입력해주세요"
                      v-model="projectName"
                      ref="projectName"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    프로젝트금액<br class="only_mobile" />(vat별도)<span
                      class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <input
                      type="text"
                      class="w_250 amount-input"
                      placeholder="프로젝트금액을 입력해주세요"
                      v-model="formattedRealAmount"
                      ref="formattedRealAmount"
                      @input="formatRealAmount"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
                <tr>
                  <th>추가금액<br class="only_mobile" />(vat별도)</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250 amount-input"
                        placeholder="추가금액을 입력해주세요"
                        v-model="formattedExtraAmount"
                        ref="formattedExtraAmount"
                        @input="formatExtraAmount"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      />
                      * 추가금액은 입력 되었을 경우만 외주 업체들이 확인 할 수
                      있습니다.
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>지역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_250"
                        v-model="selectedArea"
                        @change="handleAreaChange"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="area in areas"
                          :key="area.id"
                          :value="area.id"
                        >
                          {{ area.areaName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    프로젝트<br class="only_mobile" />시작일<span
                      class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedStartDate"
                        @change="addStartDate"
                        :disabled="
                          mizeStatus === 'F' ||
                          mizeStatus === 'C' ||
                          mizeStatus === 'YY' ||
                          mizeStatus === 'B' ||
                          mizeStatus === 'E'
                        "
                      />

                      <div
                        v-for="(date, index) in selectedStartDates"
                        :key="index"
                        style="display: flex; align-items: center"
                      >
                        <!--v-for="(date, index) in selectedStartDates" 라디오 버튼 리스트 -->
                        <label
                          :key="index"
                          :style="{
                            color: choiceSelDate === date ? 'red' : 'black', // 동일한 값일 경우 빨간색
                            fontWeight:
                              choiceSelDate === date ? 'bold' : 'normal', // 동일한 값일 경우 굵게 표시
                          }"
                        >
                          <input
                            type="radio"
                            :value="date"
                            v-model="choiceSelDate"
                            :checked="choiceSelDate === date"
                            :disabled="
                              mizeStatus === 'F' ||
                              mizeStatus === 'C' ||
                              mizeStatus === 'YY' ||
                              mizeStatus === 'B' ||
                              mizeStatus === 'E'
                            "
                          />
                          {{ date }} ({{ getDayOfWeek(date) }})
                          <!-- 업체가 선택한 날짜에만 '(업체선택)' 추가 -->
                          <span v-if="choiceSelDate === date"
                            >(업체선택완료)</span
                          >
                        </label>

                        <!-- X 버튼 추가 -->
                        <button
                          type="button"
                          @click="removeStartDate(index)"
                          style="margin-left: 10px; color: red"
                          v-if="mizeStatus === 'N' || mizeStatus === 'Y'"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>프로젝트<br class="only_mobile" />상세내용</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="상세설명을 입력하세요. "
                        v-model="detailDesc"
                        ref="detailDesc"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>투입자재목록<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <!-- 자재 선택 dropdown -->
                      <multiselect
                        v-model="selectedMaterial"
                        :options="materials"
                        :custom-label="customMaterialLabel"
                        track-by="id"
                        placeholder="자재를 검색하세요"
                        @select="addMaterial"
                        :searchable="true"
                        :close-on-select="true"
                        :allow-empty="false"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      />
                    </div>
                    <!-- 선택된 자재 목록 -->
                    <div
                      v-for="(material, index) in selectedMaterials"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{
                        material.materialsName +
                        (material.modelName
                          ? " (" + material.modelName + ")"
                          : "")
                      }}</span>
                      <div class="btns">
                        <button
                          type="button"
                          @click="decreaseQuantityByTen(index)"
                          v-show="mizeStatus !== 'F' && mizeStatus !== 'C'"
                        >
                          -10
                        </button>
                        <button
                          type="button"
                          @click="decreaseQuantity(index)"
                          v-show="mizeStatus !== 'F' && mizeStatus !== 'C'"
                        >
                          -1
                        </button>
                        <span class="quanity">{{ material.quantity }}</span>
                        <button
                          type="button"
                          @click="increaseQuantity(index)"
                          v-show="mizeStatus !== 'F' && mizeStatus !== 'C'"
                        >
                          +1
                        </button>
                        <button
                          type="button"
                          @click="increaseQuantityByTen(index)"
                          v-show="mizeStatus !== 'F' && mizeStatus !== 'C'"
                        >
                          +10
                        </button>
                      </div>
                      <button
                        type="button"
                        class="delbtn on"
                        @click="removeMaterial(index)"
                        v-show="mizeStatus !== 'F' && mizeStatus !== 'C'"
                      >
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="내용을 입력하세요. "
                        v-model="addMemo"
                        ref="addMemo"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (영업)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="salesmanager in salesManagers"
                        :key="salesmanager.userId"
                        type="button"
                        :class="{
                          on: selectedSalesManager === salesmanager.userId,
                        }"
                        @click="selectSalesManager(salesmanager.userId)"
                      >
                        {{ salesmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (PM)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="pmmanager in pmManagers"
                        :key="pmmanager.userId"
                        type="button"
                        :class="{ on: selectedPmManager === pmmanager.userId }"
                        @click="selectPmManager(pmmanager.userId)"
                      >
                        {{ pmmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C') -->
                <!-- <tr>                  
                  <th>프로젝트상태</th>
                  <td>
                    <div class="inner_td">
                      <button>프로젝트완료</button>
                      <button>계산서발급완료</button>
                      <button>수주철회</button>
                      <button>프로젝트취소</button>
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">
              고객사 정보 (해당 부분은 수주한 업체에만 보이는 정보입니다.)
            </div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <!-- 고객사 상호 // 수주한 사람한테만 보임 수주하기전에도 칸은 표시하고 “수주 후에 확인 가능” 이라고 표시하기 -->
                <tr>
                  <th>고객사 상호<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="고객사 상호명을 입력해주세요"
                      v-model="customerName"
                      ref="customerName"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
                <!-- 상세주소 // 수주한 사람한테만 보임 > 수주하기전에도 칸은 표시하고 “수주 후에 확인 가능” 이라고 표시하기 -->
                <tr>
                  <th>상세주소<span class="text_red">*</span></th>
                  <td class="td_address">
                    <div>
                      <input
                        type="text"
                        class="w_150"
                        placeholder="우편번호"
                        v-model="postalCode"
                        ref="postalCode"
                        readonly
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      />
                      <button
                        type="button"
                        class="keep"
                        @click="openPostcodePopup"
                        v-show="mizeStatus !== 'F' && mizeStatus !== 'C'"
                      >
                        주소찾기
                      </button>
                    </div>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="주소"
                      v-model="address"
                      ref="address"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                    <input
                      type="text"
                      class="w_250"
                      placeholder="상세주소"
                      v-model="detailedAddress"
                      ref="detailedAddress"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="mizeStatus !== 'N'" class="tbl_search tbl_info">
            <div class="part_title">프로젝트 담당자 정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr
                  v-if="
                    mizeStatus === 'Y' ||
                    mizeStatus === 'YY' ||
                    mizeStatus === 'B' ||
                    mizeStatus == 'C'
                  "
                >
                  <th>수주 업체명</th>
                  <td>
                    <span>{{ choiceCompanyName || "데이터 없음" }}</span>
                  </td>
                </tr>
                <tr
                  v-if="
                    mizeStatus === 'Y' ||
                    mizeStatus === 'YY' ||
                    mizeStatus === 'B' ||
                    mizeStatus == 'C'
                  "
                >
                  <th>수주 담당자</th>
                  <td>
                    <div v-if="choiceCompanyId !== companyid">
                      <span>{{ choiceUserName }}</span>
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="
                    mizeStatus === 'Y' ||
                    mizeStatus === 'YY' ||
                    mizeStatus === 'B' ||
                    mizeStatus == 'C'
                  "
                >
                  <th>수주 일시</th>
                  <td>
                    <span>{{ choiceDtm || "데이터 없음" }}</span>
                  </td>
                </tr>
                <!-- 총 투입 예정 인원수 -->
                <tr>
                  <th>총 투입 예정 인원수<span class="text_red">*</span></th>
                  <td>
                    <!-- mizeStatus 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{
                        totalPersonnel ? totalPersonnel + " 명" : "데이터 없음"
                      }}</span>
                    </div>
                  </td>
                </tr>
                <!-- 투입 인원 프로젝트 담당자 이름 -->
                <tr>
                  <th>프로젝트 담당자<span class="text_red">*</span></th>
                  <td>
                    <!-- mizeStatus 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{ representativeName || "데이터 없음" }}</span>
                    </div>
                  </td>
                </tr>
                <!-- 연락처 -->
                <tr>
                  <th>프로젝트 담당자 연락처<span class="text_red">*</span></th>
                  <td>
                    <!-- mizeStatus 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{ representativeContact || "데이터 없음" }}</span>
                    </div>
                  </td>
                </tr>
                <!-- 세금계산서 발급일 -->
                <tr v-if="mizeStatus === 'YY'">
                  <th>세금계산서 발급일<span class="text_red">*</span></th>
                  <td>
                    <!-- selectedBillDate 값이 있으면 문구로 표시 -->
                    <div v-if="selectedBillDate">
                      <span>{{ selectedBillDate }}</span>
                    </div>
                    <!-- 값이 없으면 입력 필드로 표시 -->
                    <div v-else>
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedBillDate"
                      />
                    </div>
                    <button
                      v-if="selectedBillDate && userPermission === 'M'"
                      type="button"
                      class="keep"
                      @click="billStatusConstruction"
                    >
                      계산서 발급 확인
                    </button>
                  </td>
                </tr>
                <!-- 세금계산서 발급일 -->
                <tr v-if="mizeStatus === 'B'">
                  <th>세금계산서 발급일<span class="text_red">*</span></th>
                  <td>
                    <!-- mizeStatus가 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{ selectedBillDate }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="mizeStatus !== 'N'" class="tbl_search tbl_info index">
            <div class="part_title">수주업체 평가리스트 정보</div>
            <table>
              <thead>
                <tr>
                  <th>항목</th>
                  <th style="text-align: center">평가 점수</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in checklistItems" :key="item.checkId">
                  <td>{{ item.checkText }}</td>
                  <td>
                    <div class="radio-group">
                      <div
                        v-for="score in 10"
                        :key="score"
                        class="radio-wrapper"
                      >
                        <input
                          type="radio"
                          :id="`score-${score}-${item.checkId}`"
                          :name="`score-${item.checkId}`"
                          :value="score"
                          v-model="selectedScores[item.checkId]"
                          :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                          class="hidden-radio"
                        />
                        <label
                          :for="`score-${score}-${item.checkId}`"
                          class="custom-label"
                        >
                          {{ score }}
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="
              mizeStatus === 'YY' || mizeStatus === 'B' || mizeStatus === 'E'
            "
            class="tbl_search tbl_info index"
          >
            <div class="part_title">수주업체 사후평가리스트 정보</div>
            <table>
              <thead>
                <tr>
                  <th>항목</th>
                  <th style="text-align: center">평가 점수</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item2 in checklistItems2" :key="item2.checkId">
                  <td>{{ item2.checkText }}</td>
                  <td>
                    <div class="radio-group">
                      <div
                        v-for="score in 10"
                        :key="score"
                        class="radio-wrapper"
                      >
                        <input
                          type="radio"
                          :id="`score-${score}-${item2.checkId}`"
                          :name="`score-${item2.checkId}`"
                          :value="score"
                          v-model="selectedScores2[item2.checkId]"
                          :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                          class="hidden-radio"
                        />
                        <label
                          :for="`score-${score}-${item2.checkId}`"
                          class="custom-label"
                        >
                          {{ score }}
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="btn_bottom_a">
            <button
              v-if="mizeStatus === 'N' || mizeStatus === 'Y'"
              type="button"
              class="on"
              @click="checkChanges"
            >
              저장
            </button>
            <button
              v-if="
                mizeStatus === 'YY' || mizeStatus === 'B' || mizeStatus === 'E'
              "
              type="button"
              class="on"
              @click="checkChanges2"
            >
              사후평가 저장
            </button>
            <button type="button" @click="goToConstructionList">목록</button>
            <button
              v-if="mizeStatus === 'Y'"
              type="button"
              @click="CompleteConstruction"
            >
              프로젝트완료
            </button>
            <button
              v-if="mizeStatus === 'Y'"
              type="button"
              @click="checkWithdrawal"
            >
              수주철회
            </button>
            <button
              v-if="mizeStatus === 'N' || mizeStatus === 'Y'"
              type="button"
              @click="checkCancel"
            >
              프로젝트취소
            </button>
          </div>
        </article>
      </div>
    </div>
    <div style="margin-top: 10px"></div>
    <CommentSection :parameterId="this.id" :boardType="'프로젝트'" />
  </div>
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box" style="width: 400px">
      <div class="d_pop_top">
        <div class="title">프로젝트 취소 (마이즈가 취소하는 경우)</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>사유</th>
                <td>
                  <textarea
                    placeholder="상세 사유를 입력해주세요. "
                    v-model="cancelMemo"
                    ref="cancelMemo"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="dpop_bottom_btns">
        <button type="button" @click="CancelConstruction">확인</button>
        <button type="button" @click="hideModal">취소</button>
      </div>
    </div>
  </div>
  <div
    id="Modal2"
    class="d_pop"
    :style="{ display: isModalVisible2 ? 'block' : 'none' }"
    v-if="isModalVisible2"
  >
    <div class="d_pop_box" style="width: 400px">
      <div class="d_pop_top">
        <div class="title">수주 철회 (업체가 철회하겠다는 경우)</div>
        <a @click="hideModal2">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>사유</th>
                <td>
                  <textarea
                    placeholder="상세 사유를 입력해주세요. "
                    v-model="withDrawalMemo"
                    ref="withDrawalMemo"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <button type="button" @click="saveWithdrawal">확인</button>
        <button type="button" @click="hideModal">취소</button>
      </div>
    </div>
  </div>
  <!-- 변경된 이유 입력 모달 -->
  <ReasonModal
    v-if="isModalVisible3"
    @close-modal="closeModal"
    :isModalVisible3="isModalVisible3"
    :showModalForFields="showModalForFields"
    :currentFieldIndex="currentFieldIndex"
    @submit-reason="submitChangeReason"
  />
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import CommentSection from "@/components/comments/CommentSection.vue";
import ReasonModal from "@/components/comments/ReasonModal.vue"; // 모달 컴포넌트 import

export default {
  name: "ProjectView",
  components: {
    Multiselect,
    CommentSection,
    ReasonModal,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      choiceSelDate: "", // 선택된 날짜 값
      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
      materials: [], // 자재 목록을 저장할 배열
      selectedMaterial: null, // 선택된 자재를 저장할 변수
      selectedMaterials: [], // 선택된 자재 목록을 저장할 배열
      selectedStartDate: "", // 선택된 프로젝트 시작일
      selectedStartDates: [], // 선택된 프로젝트 시작일 목록
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      salesManagers: [], // 영업 담당자 목록을 저장할 배열
      selectedSalesManager: "", // 선택된 영업 담당자
      pmManagers: [], // PM 담당자 목록을 저장할 배열
      selectedPmManager: "", // 선택된 PM 담당자
      extraAmount: 0, // 실제 금액을 저장하는 변수
      formattedExtraAmount: "", // ','가 포함된 금액을 저장하는 변수
      formattedRealAmount: "", // ','가 포함된 금액을 저장하는 변수

      selectedBillDate: "",
      totalPersonnel: "", // 총 투입 예정 인원수를 저장하는 변수
      representativeName: "", // 프로젝트 담당자 이름을 저장하는 변수
      representativeContact: "", // 프로젝트 담당자 연락처를 저장하는 변수 choiceDtm: "",
      choiceCompanyId: "",
      choiceCompanyName: "",
      choiceUserId: "",
      choiceUserName: "",

      withDrawalDtm: "",
      withDrawalMemo: "",
      cancelDtm: "",
      cancelMemo: "",
      isModalVisible: false,
      isModalVisible2: false,

      // 기존 데이터
      originalData: {}, // fetchConstructionData()로 가져온 데이터를 저장

      // 변경된 항목에 대한 이유
      changeReasons: [], // 변경된 필드별 이유 저장
      showModalForFields: [], // 변경된 필드를 추적하여 모달창을 하나씩 띄우기 위한 배열
      isModalVisible3: false, // 모달창 표시 여부
      currentFieldIndex: 0, // 현재 모달창에서 보여줄 필드 인덱스

      // 업체평가리스트
      checklistItems: [],
      selectedScores: [],

      //사후평가리스트
      checklistItems2: [],
      selectedScores2: [],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    companyid() {
      return this.$store.getters.getcompanyid;
    },
    areaName() {
      // 지역 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.areas.find((area) => area.id === this.selectedArea)?.areaName ||
        "Unknown Area"
      );
    },
    mizeSalesUserName() {
      // 영업 담당자 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.salesManagers.find(
          (manager) => manager.userId === this.selectedSalesManager
        )?.adminName || "Unknown Sales Manager"
      );
    },
    mizePMUserName() {
      // PM 담당자 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.pmManagers.find(
          (manager) => manager.userId === this.selectedPmManager
        )?.adminName || "Unknown PM Manager"
      );
    },
  },
  methods: {
    closeModal() {
      this.isModalVisible3 = false; // 모달을 닫기 위해 false로 설정
    },
    // 기존 데이터와 입력 데이터를 비교하는 메서드
    checkChanges() {
      this.showModalForFields = [];

      if (this.originalData.projectName !== this.projectName) {
        this.showModalForFields.push("프로젝트명");
      }

      if (this.originalData.price !== this.formattedRealAmount) {
        this.showModalForFields.push("프로젝트금액");
      }

      if (this.originalData.addPrice !== this.formattedExtraAmount) {
        this.showModalForFields.push("추가금액");
      }

      if (this.originalData.areaId !== this.selectedArea) {
        this.showModalForFields.push("지역");
      }

      if (this.originalData.startDate !== this.selectedStartDates.join("^")) {
        this.showModalForFields.push("프로젝트시작일");
      }

      if (this.originalData.choiceSelDate !== this.choiceSelDate) {
        this.showModalForFields.push("프로젝트시작일(업체선택완료값)");
      }

      if (this.originalData.detailContent !== this.detailDesc) {
        this.showModalForFields.push("프로젝트상세내용");
      }

      // 자재 목록 및 수량 비교
      const originalMaterials = this.originalData.materials.map((mat) => ({
        id: mat.materialId,
        quantity: mat.materialCnt, // 수량도 포함
      }));

      const updatedMaterials = this.selectedMaterials.map((mat) => ({
        id: mat.id,
        quantity: mat.quantity, // 현재 변경된 수량도 포함
      }));

      // 자재 목록 또는 수량이 다르면 비교
      if (
        originalMaterials.length !== updatedMaterials.length ||
        !originalMaterials.every(
          (origMat, index) =>
            origMat.id === updatedMaterials[index].id &&
            origMat.quantity === updatedMaterials[index].quantity
        )
      ) {
        this.showModalForFields.push("투입자재목록(수량)");
      }

      if (this.originalData.etcMemo !== this.addMemo) {
        this.showModalForFields.push("특이사항");
      }

      if (this.originalData.mizeSalesUserId !== this.selectedSalesManager) {
        this.showModalForFields.push("담당자 (영업)");
      }

      if (this.originalData.mizePMUserId !== this.selectedPmManager) {
        this.showModalForFields.push("담당자 (PM)");
      }

      if (this.originalData.customerName !== this.customerName) {
        this.showModalForFields.push("고객사 상호");
      }

      if (this.originalData.zipCode !== this.postalCode) {
        this.showModalForFields.push("우편번호");
      }

      if (this.originalData.addr2 !== this.detailedAddress) {
        this.showModalForFields.push("상세주소");
      }

      // 수주업체 평가리스트 비교 로직 추가
      const originalScores = this.originalData.checklist || [];
      const updatedScores = Object.entries(this.selectedScores).map(
        ([id, score]) => ({
          checkId: id,
          score: score,
        })
      );

      if (
        originalScores.length !== updatedScores.length ||
        !originalScores.every(
          (item, index) =>
            item.checkId === updatedScores[index].checkId &&
            item.score === updatedScores[index].score
        )
      ) {
        this.showModalForFields.push("수주업체 평가리스트");
      }

      if (this.showModalForFields.length > 0) {
        this.isModalVisible3 = true;
        this.currentFieldIndex = 0;
      } else {
        // 변경된 필드가 없으면 바로 저장
        this.saveConstruction();
      }
    },
    // 기존 데이터와 입력 데이터를 비교하는 메서드
    checkChanges2() {
      this.showModalForFields = [];

      // 수주업체 사푸평가리스트 비교 로직 추가
      const originalScores2 = this.originalData.checklist2 || [];
      const updatedScores2 = Object.entries(this.selectedScores2).map(
        ([id, score]) => ({
          checkId: id,
          score: score,
        })
      );

      if (
        originalScores2.length !== updatedScores2.length ||
        !originalScores2.every(
          (item2, index) =>
            item2.checkId === updatedScores2[index].checkId &&
            item2.score === updatedScores2[index].score
        )
      ) {
        this.showModalForFields.push("수주업체 사후평가리스트");
      }

      if (this.showModalForFields.length > 0) {
        this.isModalVisible3 = true;
        this.currentFieldIndex = 0;
      } else {
        // 변경된 필드가 없으면 바로 저장
        this.saveConstruction();
      }
    },
    // 현재 모달창에서 이유를 입력하고 다음 필드로 넘어가는 메서드
    submitChangeReason(reason) {
      const field = this.showModalForFields[this.currentFieldIndex];
      let originval = "";
      let newval = "";

      if (this.mizeStatus === "N" || this.mizeStatus === "Y") {
        if (field === "프로젝트명") {
          originval = this.originalData.projectName;
          newval = this.projectName;
        }
        if (field === "프로젝트금액") {
          originval = this.originalData.price;
          newval = this.formattedRealAmount;
        }
        if (field === "추가금액") {
          originval = this.originalData.addPrice
            ? this.originalData.addPrice
            : 0;
          newval = this.formattedExtraAmount;
        }
        if (field === "지역") {
          // originalData의 지역 이름 찾기
          const originalArea = this.areas.find(
            (area) => area.id === this.originalData.areaId
          );
          const originalAreaName = originalArea
            ? originalArea.areaName
            : "데이터 없음";

          // 선택된 지역 이름 찾기
          const selectedArea = this.areas.find(
            (area) => area.id === this.selectedArea
          );
          const selectedAreaName = selectedArea
            ? selectedArea.areaName
            : "데이터 없음";

          originval = originalAreaName;
          newval = selectedAreaName;
        }

        if (field === "프로젝트시작일") {
          originval = this.originalData.startDate.replace(/\^/g, ",");
          newval = this.selectedStartDates;
        }

        if (field === "프로젝트시작일(업체선택완료값)") {
          originval = this.originalData.choiceSelDate;
          newval = this.choiceSelDate;
        }

        if (field === "프로젝트상세내용") {
          originval = this.originalData.detailContent;
          newval = this.detailDesc;
        }
        if (field === "투입자재목록(수량)") {
          // 기존 자재 목록과 수량을 문자열로 만듦
          const originalMaterialsString = this.originalData.materials
            .map((mat) => `${mat.materialName} (${mat.materialCnt}개)`)
            .join(", ");

          // 수정된 자재 목록과 수량을 문자열로 만듦
          const updatedMaterialsString = this.selectedMaterials
            .map((mat) => `${mat.materialsName} (${mat.quantity}개)`)
            .join(", ");

          originval = `${originalMaterialsString}`;
          newval = `${updatedMaterialsString}`;
        }

        if (field === "특이사항") {
          originval = this.originalData.etcMemo;
          newval = this.addMemo;
        }
        if (field === "담당자 (영업)") {
          // originalData의 영업 담당자 이름 찾기
          const originalSalesManager = this.salesManagers.find(
            (manager) => manager.userId === this.originalData.mizeSalesUserId
          );
          const originalSalesManagerName = originalSalesManager
            ? originalSalesManager.adminName
            : "데이터 없음";

          // 선택된 영업 담당자 이름 찾기
          const selectedSalesManager = this.salesManagers.find(
            (manager) => manager.userId === this.selectedSalesManager
          );
          const selectedSalesManagerName = selectedSalesManager
            ? selectedSalesManager.adminName
            : "데이터 없음";

          originval = originalSalesManagerName;
          newval = selectedSalesManagerName;
        }

        if (field === "담당자 (PM)") {
          // originalData의 PM 담당자 이름 찾기
          const originalPmManager = this.pmManagers.find(
            (manager) => manager.userId === this.originalData.mizePMUserId
          );
          const originalPmManagerName = originalPmManager
            ? originalPmManager.adminName
            : "데이터 없음";

          // 선택된 PM 담당자 이름 찾기
          const selectedPmManager = this.pmManagers.find(
            (manager) => manager.userId === this.selectedPmManager
          );
          const selectedPmManagerName = selectedPmManager
            ? selectedPmManager.adminName
            : "데이터 없음";

          originval = originalPmManagerName;
          newval = selectedPmManagerName;
        }

        if (field === "고객사 상호") {
          originval = this.originalData.customerName;
          newval = this.customerName;
        }

        if (field === "우편번호") {
          originval = this.originalData.zipCode + " " + this.originalData.addr1;
          newval = this.postalCode + " " + this.address;
        }

        if (field === "상세주소") {
          originval = this.originalData.addr2;
          newval = this.detailedAddress;
        }

        if (field === "수주업체 평가리스트") {
          // 원본 데이터에서 평가 항목과 점수를 문자열로 만듦
          const originalScoresString = this.originalData.checklist
            .map((item) => `${item.checkText} (${item.score}점)`)
            .join(", ");

          // 업데이트된 평가 항목과 점수를 문자열로 만듦
          const updatedScoresString = Object.entries(this.selectedScores)
            .map(([id, score]) => {
              const item = this.checklistItems.find((i) => i.checkId === id);
              return item ? `${item.checkText} (${score}점)` : "";
            })
            .join(", ");

          originval = originalScoresString;
          newval = updatedScoresString;
        }
      }

      if (
        this.mizeStatus === "YY" ||
        this.mizeStatus === "B" ||
        this.mizeStatus === "E"
      ) {
        if (field === "수주업체 사후평가리스트") {
          const originalScoresString2 = this.originalData.checklist2
            .map((item2) => `${item2.checkText} (${item2.score}점)`)
            .join(", ");

          const updatedScoresString2 = Object.entries(this.selectedScores2)
            .map(([id, score]) => {
              const item2 = this.checklistItems2.find((i) => i.checkId === id);
              return item2 ? `${item2.checkText} (${score}점)` : "";
            })
            .join(", ");

          originval = originalScoresString2;
          newval = updatedScoresString2;
        }
      }

      // 배열에 { fieldName, reason } 객체를 추가
      this.changeReasons.push({
        ItemName: field,
        OriginValue: originval,
        NewValue: newval,
        Reason: reason,
      });

      // 다음 필드로 이동
      this.currentFieldIndex++;

      // 모든 필드를 처리했을 때
      if (this.currentFieldIndex >= this.showModalForFields.length) {
        this.isModalVisible3 = false;
        this.saveConstruction();
      }
    },
    customMaterialLabel(option) {
      return `${option.materialsName} ${
        option.modelName ? `(${option.modelName})` : ""
      }`;
    },
    showModal2() {
      this.isModalVisible2 = true;
    },
    hideModal2() {
      this.isModalVisible2 = false;
    },
    async saveWithdrawal() {
      try {
        // 데이터 저장을 위한 API 호출 로직
        const response = await apiClient.post(
          "/api/Construction/construction-withdrawal-update",
          {
            Id: this.id,
            withDrawalMemo: this.withDrawalMemo,
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          }
        );

        if (response.status === 200) {
          this.toast.success(
            "해당 프로젝트건이 수주철회 상태로 저장되었습니다.",
            {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            }
          );
          //this.goToConstructionList();
          const randomString = new Date().getTime();

          this.$router.replace({
            path: "/projects/view/" + this.id,
            query: { reload: randomString },
          });
          //this.$router.go(0); // 현재 페이지를 새로고침
        }
      } catch (error) {
        console.error("Error saving construction data:", error);
        this.toast.error(
          "해당 프로젝트건 수주철회 상태로 저장 중 오류가 발생했습니다.",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
      }
    },
    async checkWithdrawal() {
      const confirmation = confirm("해당 프로젝트를 수주 철회 하시겠습니까?");
      if (confirmation) {
        this.isModalVisible2 = true;
      }
    },
    getDayOfWeek(dateString) {
      //console.log("Received dateString:", dateString);
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.log("Invalid date:", dateString);
        return "유효하지 않은 날짜";
      }
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      const day = date.getDay();
      return daysOfWeek[day];
    },

    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
    },
    // <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C'/정산완료-'E') -->
    // 상태에 따른 문구 반환
    getStatusText(mizeStatus) {
      switch (mizeStatus) {
        case "N":
          return "수주전";
        case "Y":
          return "수주완료";
        case "YY":
          return "프로젝트완료";
        case "B":
          return "계산서발급완료";
        case "F":
          return "수주철회";
        case "C":
          return "프로젝트취소";
        case "E":
          return "정산완료";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(mizeStatus) {
      switch (mizeStatus) {
        case "N":
          return "stat back_green"; // 수주전
        case "Y":
          return "stat back_blue"; // 수주완료
        case "YY":
          return "stat back_grey"; // 프로젝트완료
        case "B":
          return "stat back_red"; // 계산서발급완료
        case "F":
          return "stat back_orange"; // 수주철회
        case "C":
          return "stat back_lightgrey"; // 프로젝트취소
        case "E":
          return "stat back_purple"; // 정산완료
        default:
          return "stat back_default"; // 기본 값
      }
    },
    formatRealAmount(event) {
      // 입력된 값에서 숫자만 추출
      const rawValue = event.target.value.replace(/[^0-9]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.realAmount = parseInt(rawValue, 10) || 0;

      // 천 단위로 ',' 추가
      this.formattedRealAmount = this.realAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatExtraAmount(event) {
      let rawValue = event.target.value;

      // 입력 값이 '-'만 있으면 그대로 유지
      if (rawValue === "-") {
        this.formattedExtraAmount = rawValue;
        return;
      }

      // 입력된 값에서 숫자 및 음수 기호(-)만 추출
      rawValue = rawValue.replace(/[^0-9-]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.extraAmount = parseInt(rawValue, 10) || 0;

      // 음수 기호 유지한 상태로 천 단위로 ',' 추가
      this.formattedExtraAmount = this.extraAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectPmManager(pmmanagerId) {
      this.selectedPmManager = pmmanagerId;
    },
    async fetchPmManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/pm-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.pmManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.pmManagers);
        }
      } catch (error) {
        console.error("Error fetching pm managers:", error);
      }
    },
    async fetchChecklistItems() {
      const searchParam = {
        PageSize: 100,
        Page: "1",
      };

      try {
        const response = await apiClient.post(
          "/api/Check/all-check-list",
          searchParam
        ); // 예시 API 호출
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.checklistItems = Array.isArray(data.data) ? data.data : [];

          console.log("--checklist----" + JSON.stringify(this.checklistItems));
        }
      } catch (error) {
        console.error("Error fetching checklist items:", error);
      }
    },
    async fetchChecklistItems2() {
      const searchParam = {
        PageSize: 100,
        Page: "1",
      };

      try {
        const response = await apiClient.post(
          "/api/Check/all-check-list-two",
          searchParam
        ); // 예시 API 호출
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.checklistItems2 = Array.isArray(data.data) ? data.data : [];

          console.log(
            "--checklist2----" + JSON.stringify(this.checklistItems2)
          );
        }
      } catch (error) {
        console.error("Error fetching checklist items:", error);
      }
    },
    selectSalesManager(salesmanagerId) {
      this.selectedSalesManager = salesmanagerId;
    },
    async fetchSalesManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/sales-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesManagers);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
    // 프로젝트 시작일 추가
    addStartDate(event) {
      this.checkdatedevice++;
      const dateValue = event.target.value;
      const today = this.getFormattedDate();

      if (this.selectedStartDate) {
        // 이미 선택된 날짜가 있는지 확인
        const exists = this.selectedStartDates.includes(this.selectedStartDate);
        if (dateValue >= today) {
          if (!exists) {
            // 선택된 날짜가 없으면 추가
            this.selectedStartDates.push(this.selectedStartDate); // 기존 선택된 날짜는 유지하고 새로운 날짜는 선택되지 않도록 설정
            this.choiceSelDate = this.choiceSelDate || "";
            // 추가 후 날짜를 빠른 순으로 정렬
            this.selectedStartDates.sort((a, b) => new Date(a) - new Date(b));
          } else {
            this.toast.error("이미 선택된 프로젝트 시작일입니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }

          this.selectedStartDate = "";
        }
      } else {
        console.log(this.checkdatedevice);
        this.toast.error("프로젝트 등록 시 과거 날짜는 선택할 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        // 선택 후 초기화
        this.selectedStartDate = "";
      }
    },
    // 프로젝트 시작일 제거
    removeStartDate(index) {
      this.selectedStartDates.splice(index, 1);
    },
    async fetchMaterials() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Materials/all-materials-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.materials = Array.isArray(data.data) ? data.data : [];
          console.log(this.materials);
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    },
    addMaterial() {
      if (this.selectedMaterial) {
        // 선택된 자재가 이미 목록에 없으면 추가
        const exists = this.selectedMaterials.find(
          (mat) => mat.id === this.selectedMaterial.id
        );
        if (!exists) {
          this.selectedMaterials.push({
            ...this.selectedMaterial,
            quantity: 1, // 기본 수량 1로 설정
          });
          // 선택 후 드롭다운 초기화
          this.selectedMaterial = null;
        } else {
          this.toast.error("이미 선택된 자재입니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.selectedMaterial = "";
        }
      }
    },
    increaseQuantity(index) {
      this.selectedMaterials[index].quantity =
        parseInt(this.selectedMaterials[index].quantity, 10) + 1;
    },
    // 수량 10 증가 메소드
    increaseQuantityByTen(index) {
      this.selectedMaterials[index].quantity =
        parseInt(this.selectedMaterials[index].quantity, 10) + 10;
    },

    // 수량 10 감소 메소드
    decreaseQuantityByTen(index) {
      const currentQuantity = parseInt(
        this.selectedMaterials[index].quantity,
        10
      );
      if (currentQuantity > 10) {
        this.selectedMaterials[index].quantity = currentQuantity - 10;
      } else {
        this.toast.error("현재 10 이하의 수량으로 더 이상 줄일 수 없습니다.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    },
    // 수량 1 감소 메소드
    decreaseQuantity(index) {
      const currentQuantity = parseInt(
        this.selectedMaterials[index].quantity,
        10
      );
      if (currentQuantity > 1) {
        this.selectedMaterials[index].quantity = currentQuantity - 1;
      } else {
        this.toast.error("1개 미만 수량으로 선택할 수 없습니다.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    },
    removeMaterial(index) {
      this.selectedMaterials.splice(index, 1);
    },
    handleAreaChange() {
      this.currentPage = 1; // 지역 변경 시 페이지를 1로 리셋
      //this.fetchData(); // 지역 선택 시 데이터 갱신
    },
    goToConstructionList() {
      this.$router.push({ path: "/projects/list" });
    },
    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async billStatusConstruction() {
      // 사용자에게 계산서 발급 확인을 요청
      const userConfirmed = confirm("계산서 발급 확인 처리를 하시겠습니까?");

      // 사용자가 확인 버튼을 눌렀을 경우 API 호출
      if (userConfirmed) {
        try {
          // 계산서 발급 확인 위한 API 호출
          const response = await apiClient.post(
            "/api/Construction/construction-billstatus-update",
            {
              Id: this.id, // 프로젝트 ID 전달
              Status: "B", // 프로젝트완료 상태로 설정
              LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
            }
          );

          // 응답이 성공적일 경우
          if (response.status === 200) {
            this.toast.success("계산서 발급 확인이 처리되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            //this.goToConstructionList();
            const randomString = new Date().getTime();

            this.$router.replace({
              path: "/projects/view/" + this.id,
              query: { reload: randomString },
            });
          } else {
            this.toast.error("계산서 발급 확인 처리 중 실패하였습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        } catch (error) {
          console.error("Error during billstatus construction:", error);
          this.toast.error(
            "오류가 발생했습니다. 계산서 발급 확인 처리가 실패했습니다.",
            {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            }
          );
        }
      }
    },
    async checkCancel() {
      const confirmation = confirm("해당 프로젝트를 취소 처리를 하시겠습니까?");
      if (confirmation) {
        this.isModalVisible = true;
      }
    },
    async CancelConstruction() {
      try {
        // 수주 취소 처리를 위한 API 호출
        const response = await apiClient.post(
          "/api/Construction/construction-cancel-update",
          {
            Id: this.id, // 프로젝트 ID 전달
            Status: "C", // 프로젝트 취소 상태로
            cancelMemo: this.cancelMemo,
            LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
          }
        );

        // 응답이 성공적일 경우
        if (response.status === 200) {
          this.toast.success("프로젝트 취소 처리되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          //this.goToConstructionList();
          const randomString = new Date().getTime();

          this.$router.replace({
            path: "/projects/view/" + this.id,
            query: { reload: randomString },
          });
        } else {
          this.toast.error("프로젝트 취소 처리에 실패하였습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      } catch (error) {
        console.error("Error during cancel construction:", error);
        this.toast.error(
          "오류가 발생했습니다. 프로젝트 취소 처리가 실패했습니다.",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
      }
    },
    async CompleteConstruction() {
      // 수주업체 평가 리스트 점수가 모두 0 이상인지 확인
      const allScoresValid = Object.values(this.selectedScores).every(
        (score) => score > 0
      );
      // 평가 점수가 0점인 항목이 있을 경우 경고 메시지 출력
      if (!allScoresValid) {
        this.toast.error(
          "수주업체 평가 리스트에 평가되지 않은 항목이 있으니 평가를 완료 후 진행해주세요.",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 1.5초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
        return; // 점수가 유효하지 않으면 함수 실행 중단
      }

      // 사용자에게 프로젝트 완료 확인을 요청
      const userConfirmed = confirm("프로젝트 완료 처리를 하시겠습니까?");

      // 사용자가 확인 버튼을 눌렀을 경우 API 호출
      if (userConfirmed) {
        try {
          // 수주업체 평가리스트 데이터를 준비
          const evaluationData = Object.entries(this.selectedScores).map(
            ([checkId, score]) => ({
              checkId,
              score,
            })
          );
          // 프로젝트 완료 처리를 위한 API 호출
          const response = await apiClient.post(
            "/api/Construction/construction-complete",
            {
              Id: this.id, // 프로젝트 ID 전달
              Status: "YY", // 프로젝트완료 상태로 설정
              LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
              CheckLists: evaluationData, // 수주업체 평가리스트 데이터 추가
            }
          );

          // 응답이 성공적일 경우
          if (response.status === 200) {
            this.toast.success("프로젝트 완료 처리되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            //this.goToConstructionList();
            const randomString = new Date().getTime();

            this.$router.replace({
              path: "/projects/view/" + this.id,
              query: { reload: randomString },
            });
          } else {
            this.toast.error("프로젝트 완료 처리에 실패하였습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        } catch (error) {
          console.error("Error during complete construction:", error);
          this.toast.error(
            "오류가 발생했습니다. 프로젝트 완료 처리가 실패했습니다.",
            {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            }
          );
        }
      }
    },
    async saveConstruction() {
      if (this.checkFields()) {
        // 프로젝트 시작일을 '^'로 구분된 문자열로 변환
        const formattedStartDates = this.selectedStartDates.join("^");

        const changeReasons = this.changeReasons.map((reason) => {
          // 만약 NewValue가 배열이라면 문자열로 변환
          if (Array.isArray(reason.NewValue)) {
            return {
              ...reason,
              NewValue: reason.NewValue.join("^"), // 배열을 문자열로 변환
            };
          }
          return reason;
        });

        // 수주업체 평가리스트 데이터를 준비
        const evaluationData = Object.entries(this.selectedScores).map(
          ([checkId, score]) => ({
            checkId,
            score,
          })
        );

        // 수주업체 사후평가리스트 데이터를 준비
        const evaluationData2 = Object.entries(this.selectedScores2).map(
          ([checkId, score]) => ({
            checkId,
            score,
          })
        );

        // status가 'Y'일 때 확인 메시지 표시
        if (this.mizeStatus === "Y") {
          const userConfirmed = confirm(
            "해당 프로젝트건은 수주완료 인 상태입니다. \n수주업체에게 변경된 정보 공유하셔야 합니다. \n계속하시겠습니까?"
          );
          if (!userConfirmed) {
            return; // 사용자가 취소를 누르면 저장을 중단
          }
        }

        console.log("★--selectedStartDate--" + this.choiceSelDate);

        try {
          const response = await apiClient.post(
            "/api/Construction/construction-edit",
            {
              Id: this.id,
              ProjectName: this.projectName,
              Price: this.formattedRealAmount,
              AddPrice: this.formattedExtraAmount,
              StartDate: formattedStartDates,
              ChoiceSelDate: this.choiceSelDate,
              AreaId: this.selectedArea,
              AreaName: this.areaName,
              ZipCode: this.postalCode,
              Addr1: this.address,
              Addr2: this.detailedAddress,
              DetailContent: this.detailDesc,
              Materials: this.selectedMaterials.map((mat) => ({
                MaterialId: mat.id,
                MaterialCnt: mat.quantity,
                MaterialName: mat.materialsName,
              })),
              EtcMemo: this.addMemo,
              ChoiceCompanyId: this.choiceCompanyId,
              ChoiceCompanyName: this.choiceCompanyName,
              ChoiceUserId: this.choiceUserId,
              ChoiceUserName: this.choiceUserName,
              managerUserName: this.representativeName,
              managerUserPhone: this.representativeContact,
              TotalPeople: this.totalPersonnel,
              MizeSalesUserId: this.selectedSalesManager,
              MizePMUserId: this.selectedPmManager,

              MizeSalesUserName: this.mizeSalesUserName,
              MizePMUserName: this.mizePMUserName,

              CustomerName: this.customerName,
              LoginId: localStorage.getItem("userid"),
              // 필요한 다른 필드 추가
              ChangeReasons: changeReasons, //this.changeReasons, // 변경 사유 추가
              CheckLists: evaluationData, // 수주업체 평가리스트 데이터 추가
              CheckLists2: evaluationData2, // 수주업체 평가리스트 데이터 추가
            }
          );
          if (response.status === 200) {
            this.toast.success("프로젝트 정보가 성공적으로 수정되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            //this.goToConstructionList();
            const randomString = new Date().getTime();

            this.$router.replace({
              path: "/projects/view/" + this.id,
              query: { reload: randomString },
            });
          }
        } catch (error) {
          console.error("Error saving construction data:", error);
          this.toast.error("프로젝트 정보가 수정 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    async fetchConstructionData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post(
          "/api/Construction/construction-info",
          {
            Id: this.id,
            LoginId: localStorage.getItem("userid"),
          }
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.originalData = { ...data.data[0] }; // 첫 번째 데이터만 복사해 저장

          this.constructionInfo = Array.isArray(data.data) ? data.data : [];
          console.log(
            "☆☆☆☆☆☆☆☆this.constructionInfo[0]☆☆☆☆☆☆☆☆☆" +
              this.constructionInfo[0]
          );

          this.mizeStatus = this.constructionInfo[0].mizeStatus; // 추가: 상태값 저장
          this.projectName = this.constructionInfo[0].projectName;
          this.formattedRealAmount = this.constructionInfo[0].price;
          this.formattedExtraAmount = this.constructionInfo[0].addPrice;

          this.selectedArea = this.constructionInfo[0].areaId;
          // 시작일 부분 split 해서 ui 표현해야 함
          this.formattedStartDates = this.constructionInfo[0].startDate;
          this.selectedStartDates = this.formattedStartDates.split("^"); // 날짜를 배열로 변환

          this.postalCode = this.constructionInfo[0].zipCode;
          this.address = this.constructionInfo[0].addr1;
          this.detailedAddress = this.constructionInfo[0].addr2;
          this.detailDesc = this.constructionInfo[0].detailContent;

          this.choiceSelDate = data.data[0].choiceSelDate; // 프로젝트 시작일 선택 값 설정
          //this.selectedMaterials = this.constructionInfo[0].

          this.addMemo = this.constructionInfo[0].etcMemo;
          this.selectedSalesManager = this.constructionInfo[0].mizeSalesUserId;
          this.selectedPmManager = this.constructionInfo[0].mizePMUserId;
          this.customerName = this.constructionInfo[0].customerName;
          this.totalPersonnel = this.constructionInfo[0].totalPeople;
          this.representativeName = this.constructionInfo[0].managerUserName;
          this.representativeContact =
            this.constructionInfo[0].managerUserPhone;

          this.selectedBillDate = this.constructionInfo[0].billDate;
          this.choiceDtm = this.constructionInfo[0].choiceDtm;
          this.choiceCompanyId = this.constructionInfo[0].choiceCompanyId;
          this.choiceCompanyName = this.constructionInfo[0].choiceCompanyName;
          this.choiceUserId = this.constructionInfo[0].choiceUserId;
          this.choiceUserName = this.constructionInfo[0].choiceUserName;

          this.withDrawalDtm = this.constructionInfo[0].withDrawalDtm;
          this.withDrawalMemo = this.constructionInfo[0].withDrawalMemo;

          this.cancelDtm = this.constructionInfo[0].canceledDtm;
          this.cancelMemo = this.constructionInfo[0].cancelMemo;

          // 프로젝트 정보에 자재 목록이 포함되어 있는 경우 처리
          if (this.constructionInfo[0].materials) {
            this.selectedMaterials = this.constructionInfo[0].materials.map(
              (material) => ({
                id: material.materialId,
                materialsName: material.materialName,
                modelName: material.modelName || "", // 모델명이 있을 경우 포함
                quantity: material.materialCnt || 1, // 수량 정보가 없으면 기본 수량 1
              })
            );
          }

          // 수주업체 평가리스트 데이터 설정
          this.originalData.checklist = data.data[0].checkLists || [];
          this.selectedScores = {};

          // 기존 평가리스트 점수를 selectedScores에 설정
          this.originalData.checklist.forEach((item) => {
            this.selectedScores[item.checkId] = item.score || 0; // 기본값을 0으로 설정
          });

          console.log("수주업체 평가리스트:", this.originalData.checklist);

          // 수주업체 평가리스트 데이터 설정
          this.originalData.checklist2 = data.data[0].checkLists2 || [];
          this.selectedScores2 = {};

          // 기존 평가리스트 점수를 selectedScores에 설정
          this.originalData.checklist2.forEach((item2) => {
            //alert(item2.score);
            this.selectedScores2[item2.checkId] = item2.score || 0; // 기본값을 0으로 설정
          });

          console.log("수주업체 사후평가리스트:", this.originalData.checklist2);
        }
      } catch (error) {
        console.error("Error fetching constructionInfo data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    checkFields() {
      if (!this.projectName) {
        this.$refs.projectName.focus();
        this.toast.error("프로젝트명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.formattedRealAmount) {
        this.$refs.formattedRealAmount.focus();
        this.toast.error("프로젝트금액을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (this.selectedArea === "0") {
        // 전체보기 (기본값) 또는 선택되지 않은 상태
        this.toast.error("지역을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (this.selectedStartDates.length === 0) {
        // 프로젝트 시작일이 선택되지 않았을 때
        this.toast.error("프로젝트 시작일을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.selectedMaterials.length === 0) {
        // 투입자재목록이 선택되지 않았을 때
        this.toast.error("투입자재목록을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.selectedSalesManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(영업)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.selectedPmManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(PM)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.customerName) {
        // 고객사 상호가 입력되지 않았을 때
        this.$refs.customerName.focus();
        this.toast.error("고객사 상호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.detailedAddress) {
        this.$refs.detailedAddress.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("상세주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      return true;
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchMaterials(); // 자재 데이터를 가져오기
    this.fetchSalesManagers(); // 영업 담당자 목록을 가져옴
    this.fetchPmManagers(); // 영업 담당자 목록을 가져옴
    this.fetchConstructionData(); // 프로젝트 상세정보
    this.fetchChecklistItems(); // 컴포넌트가 마운트될 때 체크리스트 항목을 불러옴
    this.fetchChecklistItems2(); // 컴포넌트가 마운트될 때 체크리스트 항목을 불러옴
  },
};
</script>

<style scoped>
/* 기본 입력 필드 오른쪽 정렬 */
input.amount-input {
  text-align: right; /* 입력값은 오른쪽 정렬 */
}

/* placeholder 왼쪽 정렬 */
input.amount-input::placeholder {
  text-align: left; /* placeholder는 왼쪽 정렬 */
}
.multiselect__input {
  position: relative;
  z-index: 1;
}

.multiselect__content {
  position: absolute;
  top: calc(100% + 5px); /* 입력 필드 바로 아래로 위치시킴 */
  z-index: 9999;
}
</style>
