<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">프로젝트 목록</div>
        <div class="description">프로젝트 내역을 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'M'">
                  <th>수주업체별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>상태별</th>
                  <td>
                    <div class="inner_td">
                      <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C') -->
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        수주전
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        수주완료
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'YY' }"
                        @click="updateStatus('YY')"
                      >
                        프로젝트완료
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'B' }"
                        @click="updateStatus('B')"
                      >
                        계산서발급완료
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'E' }"
                        @click="updateStatus('E')"
                      >
                        정산완료
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'F' }"
                        @click="updateStatus('F')"
                      >
                        수주철회
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'C' }"
                        @click="updateStatus('C')"
                      >
                        프로젝트취소
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="projectName">프로젝트명</option>
                        <option value="areaName">지역</option>
                        <option value="companyName">수주업체</option>
                        <option value="pmName">PM담당자</option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalConstructions }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>건씩</span>
            </div>
            <button
              type="button"
              v-if="userType === 'M'"
              @click="goToConstructionAdd"
            >
              등록하기
            </button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="constructionList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col v-if="userType === 'M'" style="width: 5%" />
                  <col style="width: 5%" />
                  <col style="width: 9%" />
                  <col />
                  <col style="width: 10%" />
                  <col style="width: 14%" />
                  <col style="width: 12%" />
                  <col style="width: 8%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th v-if="userType === 'M'">
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">지역</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">프로젝트명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">프로젝트<br />시작일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">금액 (vat별도)</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>PM담당자 / <br />수주담당자</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="construction in paginatedConstructions"
                    :key="construction.Id"
                  >
                    <td v-if="userType === 'M'" class="o1">
                      <input
                        type="checkbox"
                        @change="toggleSelection(construction.id)"
                        :checked="
                          selectedConstructions.includes(construction.id)
                        "
                      />
                    </td>
                    <td class="no_mobile">{{ construction.no }}</td>
                    <td class="seat o3">
                      <span class="only_mobile">지역 : </span>
                      <p class="long_p">{{ construction.areaName }}</p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div>
                        <router-link
                          v-if="userType === 'M'"
                          :to="{
                            name: 'ProjectView',
                            params: { id: construction.id },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ construction.projectName }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                      <div>
                        <span>수주업체 : </span>
                        <span>{{
                          construction.choiceCompanyName
                            ? construction.choiceCompanyName
                            : "미정"
                        }}</span>
                      </div>
                    </td>
                    <td class="name o5 o_br">
                      <span class="only_mobile">프로젝트시작일 : </span>
                      <span v-if="construction.mizeStatus === 'N'">
                        {{ getFormattedStartDate(construction.startDate) }}
                      </span>
                      <span v-else>
                        {{ construction.choiceSelDate }}
                      </span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">금액 : </span>
                      <span>{{ construction.price }} 원</span>
                    </td>
                    <td class="phone o6 o_br">
                      <span class="only_mobile">PM담당자 : </span>
                      <span>{{
                        construction.mizePMUserName
                          ? construction.mizePMUserName
                          : "미지정"
                      }}</span
                      ><br />
                      <span class="only_mobile" style="margin-left: 20px"
                        >수주담당자 :
                      </span>
                      <span>{{
                        construction.choiceUserName
                          ? construction.choiceUserName
                          : "미정"
                      }}</span>
                    </td>
                    <td class="center_td o_stat">
                      <div :class="getStatusClass(construction.mizeStatus)">
                        <span>{{
                          getStatusText(construction.mizeStatus)
                        }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 11%" />
                  <col />
                  <col style="width: 12%" />
                  <col style="width: 14%" />
                  <col style="width: 12%" />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">지역</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">프로젝트명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">프로젝트<br />시작일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">금액</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>PM담당자</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="10">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div v-if="userType === 'M'">
              <a @click.prevent="deleteSelected">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "ProjectList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      constructionList: [], // 사업장 목록 데이터를 저장할 배열
      selectedConstructions: [], // 선택된 사업장들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "projectName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalConstructions: 0, // 총 사업장 수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedConstructions() {
      if (!Array.isArray(this.constructionList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.constructionList);
      console.log(
        "Paginated constructionList: ",
        this.constructionList.slice(start, end)
      );
      return this.constructionList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalConstructions / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
  methods: {
    getFormattedStartDate(startDate) {
      if (!startDate) {
        return "";
      }

      // 날짜 문자열을 '^'로 분리
      const dates = startDate.split("^");

      // 첫 번째 날짜는 그대로 표시하고, 나머지 날짜가 있다면 "(선택가능)" 추가
      if (dates.length > 1) {
        return `${dates[0]} (선택가능)`;
      }

      return dates[0]; // 첫 번째 날짜만 반환
    },
    // <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C') -->
    // 상태에 따른 문구 반환
    getStatusText(status) {
      console.log("status---1---", status);
      switch (status) {
        case "N":
          return "수주전";
        case "Y":
          return "수주완료";
        case "YY":
          return "프로젝트완료";
        case "B":
          return "계산서발급완료";
        case "F":
          return "수주철회";
        case "C":
          return "프로젝트취소";
        case "E":
          return "정산완료";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(status) {
      console.log("status---2---", status);
      switch (status) {
        case "N":
          return "stat back_green"; // 수주전
        case "Y":
          return "stat back_blue"; // 수주완료
        case "YY":
          return "stat back_grey"; // 프로젝트완료
        case "B":
          return "stat back_red"; // 계산서발급완료
        case "F":
          return "stat back_orange"; // 수주철회
        case "C":
          return "stat back_lightgrey"; // 프로젝트취소
        case "E":
          return "stat back_purple"; // 정산완료
        default:
          return "stat back_default"; // 기본 값
      }
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.constructionList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },

    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedConstructions = this.constructionList.map(
          (construction) => construction.id
        );
      } else {
        this.selectedConstructions = [];
      }
    },
    toggleSelection(constructionId) {
      const index = this.selectedConstructions.indexOf(constructionId);

      if (index > -1) {
        this.selectedConstructions.splice(index, 1);
      } else {
        this.selectedConstructions.push(constructionId); // 기존 코드를 이 줄로 수정
      }
    },
    deleteSelected() {
      if (this.selectedConstructions.length === 0) {
        this.toast.error("원하시는 삭제 프로젝트 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 프로젝트 항목을 삭제하시겠습니까?")) {
        this.DeleteConstruction(this.selectedConstructions);
      }
    },
    async DeleteConstruction(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Construction/construction-delete", {
            Id: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to delete construction for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedConstructions = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error deleting construction:", error);
      }
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.constructionList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.constructionList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.constructionList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.constructionList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.constructionList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.constructionList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    goToConstructionAdd() {
      this.$router.push({ path: "/projects/add" });
    },
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.constructionList = []; // 회의실 룸 목록 초기화
      this.fetchData();
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";

        if (this.userType === "M") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
        } else {
          v_companyId = "0";
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          LoginUserId: localStorage.getItem("userid"),
        };

        const response = await apiClient.post(
          "/api/Construction/all-construction-mize-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.constructionList = Array.isArray(data.data) ? data.data : [];
          this.totalConstructions = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching construction list data:", error);
      }
    },
  },
  mounted() {
    this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchData();
  },
};
</script>
