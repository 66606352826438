<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 등록</div>
        <div class="description">
          공지사항 내용을 등록합니다. <span class="text_red">(* 필수항목)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">공지사항 정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상단고정 유무<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: selectedFixStatus === 'Y' }"
                        @click="selectFixStatus('Y')"
                      >
                        예
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedFixStatus === 'N' }"
                        @click="selectFixStatus('N')"
                      >
                        아니오
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>공지사항 분류<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="noticecategory in noticeCategorys"
                        :key="noticecategory.categoryId"
                        type="button"
                        :class="{
                          on:
                            selectedNoticeCategory ===
                            noticecategory.categoryId,
                        }"
                        @click="selectNoticeCategory(noticecategory.categoryId)"
                      >
                        {{ noticecategory.categoryName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>공지사항 제목<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      placeholder="공지사항 제목을 입력해주세요"
                      v-model="noticeSubject"
                      ref="noticeSubject"
                    />
                    <span style="margin-right: 5px"></span>
                    <input
                      type="color"
                      v-model="titleColor"
                      class="custom-color-input"
                      title="제목 색상 선택"
                    />
                    <span style="margin-left: 5px; color: darkgray"
                      >( * 제목 색상을 선택 할 수 있습니다. / 기본은 검정색
                      )</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <quill-editor
              v-model="content"
              ref="content"
              :options="editorOptions"
              class="custom-quill-editor"
            />
          </div>

          <!-- 첨부파일 입력 -->
          <div class="tbl_search tbl_info">
            <div class="part_title"></div>
            첨부파일 ( 100MB 이상의 파일은 업로드 할 수 없습니다.)
            <div class="file-upload-container">
              <input
                id="file-upload"
                type="file"
                @change="handleFileUpload"
                multiple
              />
              <span v-if="uploadedFiles.length > 0"
                >총 선택 첨부파일 {{ uploadedFiles.length }}개</span
              >
              <span v-else> 총 선택 첨부파일 0개</span>
            </div>
            <ul>
              <li v-for="(file, index) in uploadedFiles" :key="index">
                {{ file.name }}
                <button @click="removeFile(index)">삭제</button>
              </li>
            </ul>
          </div>

          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveNotice">등록</button>
            <button type="button" @click="goToNoticeList">취소</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
// Quill 및 이미지 리사이즈 모듈 import
import { quillEditor, Quill } from "vue3-quill";
import ImageResize from "quill-image-resize";
// Quill에 이미지 리사이즈 모듈 등록
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "NoticesAdd",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedFixStatus: "", // 선택된 상단고정 유무
      noticeCategorys: [], // PM 담당자 목록을 저장할 배열
      selectedNoticeCategory: "", // 선택된 공지사항 카테고리 값
      noticeSubject: "", // 공지사항 제목
      titleColor: "#000000", // 제목의 기본 색상 (검정색)
      content: "", // 에디터 내용
      editorOptions: {
        modules: {
          // 이미지 리사이즈 모듈 활성화
          imageResize: {},
          toolbar: [
            [{ font: [] }], // 글꼴 선택
            [{ size: [] }], // 글자 크기
            ["bold", "italic", "underline", "strike"], // 굵게, 기울임, 밑줄, 취소선
            [{ color: [] }, { background: [] }], // 글자 색상 및 배경색
            [{ script: "sub" }, { script: "super" }], // 아래첨자, 위첨자
            [{ header: 1 }, { header: 2 }], // 헤더 1, 헤더 2
            ["blockquote", "code-block"], // 인용구, 코드 블록
            [{ list: "ordered" }, { list: "bullet" }], // 순서 있는 목록, 불릿 목록
            [{ indent: "-1" }, { indent: "+1" }], // 들여쓰기, 내어쓰기
            [{ align: [] }], // 텍스트 정렬
            ["link", "image", "video"], // 링크, 이미지, 비디오 삽입
            ["clean"], // 서식 제거
          ],
        },
      }, // quill 에디터 옵션
      uploadedFiles: [], // 첨부된 파일 리스트
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
  },
  components: {
    quillEditor,
  },
  methods: {
    // 파일 업로드 처리
    handleFileUpload(event) {
      const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB로 파일 크기 제한
      const files = event.target.files;

      // 이미 선택된 파일 개수와 새로 선택한 파일 개수를 합산하여 10개 초과 여부 확인
      if (this.uploadedFiles.length + files.length > 10) {
        this.toast.error("첨부파일은 최대 10개까지 선택 가능합니다.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
        return; // 파일 추가 작업 중단
      }

      const duplicateFiles = [];
      const oversizedFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // 파일명이 중복되는지 확인
        const duplicate = this.uploadedFiles.some(
          (uploadedFile) => uploadedFile.name === file.name
        );

        // 파일 크기 제한 확인 (5MB)
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(file.name); // 크기 제한을 초과한 파일명을 배열에 추가
          continue; // 용량 초과한 파일은 추가하지 않음
        }

        if (duplicate) {
          duplicateFiles.push(file.name); // 중복된 파일명을 배열에 추가
        } else {
          this.uploadedFiles.push(file); // 중복되지 않은 경우에만 업로드 리스트에 추가
        }
      }

      // 중복된 파일이 있는 경우 경고 메시지 출력
      if (duplicateFiles.length > 0) {
        this.toast.error(
          `다음 파일은 이미 추가되었습니다.\r\n\r\n${duplicateFiles.join(
            "\r\n"
          )}`,
          {
            position: "top-center",
            timeout: 1500,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true,
            closeButton: false,
          }
        );
      }

      // 용량 초과된 파일이 있는 경우 경고 메시지 출력
      if (oversizedFiles.length > 0) {
        this.toast.error(
          `다음 파일은 크기 제한(100MB)을 초과했습니다.\r\n\r\n${oversizedFiles.join(
            "\r\n"
          )}`,
          {
            position: "top-center",
            timeout: 1500,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true,
            closeButton: false,
          }
        );
      }
    },
    // 파일 삭제
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
    },
    selectNoticeCategory(noticecategoryId) {
      this.selectedNoticeCategory = noticecategoryId;
    },
    async fetchNoticeCategorys() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/NoticeCategory/notice-category-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.noticeCategorys = Array.isArray(data.data) ? data.data : [];
          console.log(this.noticeCategorys);
        }
      } catch (error) {
        console.error("Error fetching notice Categorys:", error);
      }
    },
    selectFixStatus(fixstatus) {
      this.selectedFixStatus = fixstatus;
    },
    goToNoticeList() {
      this.$router.push({ path: "/notices/list" });
    },
    async saveNotice() {
      // async 키워드 추가
      if (this.checkFields()) {
        try {
          // QuillEditor에서 HTML을 가져옴
          const quillEditor =
            this.$refs.content.$el.querySelector(".ql-editor");

          // 이미지 태그에서 src 속성 추출
          const imgTags = quillEditor.querySelectorAll("img");
          const imageSrcArray = [];

          // 이미지가 있는 경우 처리
          for (const img of imgTags) {
            const imgSrc = img.getAttribute("src");

            // base64 이미지를 처리하는 경우
            if (imgSrc.startsWith("data:image")) {
              const uniqueFileName = `image_${new Date().getTime()}.png`; // 파일명에 타임스탬프 추가
              const imageFile = this.dataURLtoFile(imgSrc, uniqueFileName); // base64를 파일로 변환
              const imageUrl = await this.uploadImage(imageFile); // 이미지 업로드

              if (imageUrl) {
                img.setAttribute("src", imageUrl); // 업로드된 URL로 이미지 src 변경
              }
            }
            imageSrcArray.push(imgSrc); // src 값을 배열에 추가
          }

          // HTML 콘텐츠를 다시 가져옴 (이미지 URL로 변경된 상태)
          const updatedContent = quillEditor.innerHTML;

          // 준비된 데이터를 payload로 구성
          // const payload = {
          //   FixYN: this.selectedFixStatus,
          //   CategoryId: this.selectedNoticeCategory,
          //   NoticeSubject: this.noticeSubject,
          //   NoticeSubjectColor: this.titleColor,
          //   NoticeContent: updatedContent, // 이미지 URL로 대체된 HTML 콘텐츠
          //   LoginUserId: this.userId,
          //   // images: imageSrcArray, // 이미지 src 리스트를 함께 보냄 (선택 사항)
          // };

          const formData = new FormData();
          formData.append("FixYN", this.selectedFixStatus);
          formData.append("CategoryId", this.selectedNoticeCategory);
          formData.append("NoticeSubject", this.noticeSubject);
          formData.append("NoticeSubjectColor", this.titleColor);
          formData.append("NoticeContent", updatedContent);
          formData.append("LoginUserId", this.userId);

          // 첨부파일 추가
          for (let i = 0; i < this.uploadedFiles.length; i++) {
            formData.append("files", this.uploadedFiles[i]);
          }

          // API에 데이터 전송
          const response = await apiClient.post(
            "/api/Notice/notice-add",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
            //payload
          );
          if (response.status === 200) {
            this.toast.success("공지사항이 성공적으로 등록되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToNoticeList();
          }
        } catch (error) {
          console.error("Error saving notice data:", error);
          this.toast.error("공지사항 등록 중 오류가 발생했습니다.", {
            position: "top-center",
            timeout: 1500,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      }
    },
    // base64 데이터를 파일로 변환하는 함수
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 이미지 파일을 서버에 업로드하는 함수
    async uploadImage(imageFile) {
      const formData = new FormData();
      formData.append("image", imageFile);

      try {
        const response = await apiClient.post(
          "/api/Notice/notice-image-upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.status === 200) {
          return response.data.imageUrls[0]; // 서버에서 반환된 이미지 URL
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }

      return null; // 이미지 업로드 실패 시 null 반환
    },
    checkFields() {
      if (!this.selectedFixStatus) {
        this.toast.error("상단고정 유무 상태를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        return false;
      }
      if (!this.selectedNoticeCategory) {
        this.toast.error("공지사항 분류를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.noticeSubject) {
        this.$refs.noticeSubject.focus();
        this.toast.error("공지사항 제목을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      // QuillEditor 내용 체크 (HTML 태그 제거 후 공백 체크)
      const quillEditor = this.$refs.content.$el.querySelector(".ql-editor");
      const editorContent = quillEditor.innerHTML
        .replace(/<(.|\n)*?>/g, "")
        .trim();

      if (!editorContent) {
        this.$refs.content.$el.querySelector(".ql-editor").focus(); // QuillEditor 포커스 설정
        this.toast.error("공지사항 내용을 입력해주세요.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.fetchNoticeCategorys();
  },
};
</script>

<style scoped>
.custom-quill-editor >>> .ql-editor {
  line-height: normal; /* 기본적인 라인 높이로 설정 */
  white-space: normal; /* 줄바꿈이 발생할 때 적절히 처리되도록 설정 */
  cursor: text; /* 텍스트 커서를 강제 설정 */
  flex: 1;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll; /* 스크롤이 생길 수 있도록 강제 설정 */
}

.custom-quill-editor >>> .ql-editor p {
  margin: 0;
}

/* 이미지가 부모 컨테이너의 너비를 넘지 않도록 설정 */
.custom-quill-editor img {
  max-width: 100%; /* 이미지를 부모 컨테이너에 맞게 축소 */
  height: auto; /* 이미지 비율을 유지하면서 크기 조정 */
  display: block; /* 이미지 주위에 공간이 없도록 block으로 설정 */
}
/* 색상 선택기의 기본 회색 배경 제거 및 커스터마이징 */
.custom-color-input {
  border: none; /* 기본 테두리 제거 */
  background: transparent; /* 배경 투명하게 */
  width: 30px; /* 너비 지정 */
  height: 30px; /* 높이 지정 */
  cursor: pointer; /* 커서 변경 */
  padding: 0;
}

.custom-color-input::-webkit-color-swatch {
  border: none; /* 기본 테두리 제거 */
}

.custom-color-input::-webkit-color-swatch-wrapper {
  padding: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
}

ul li button {
  margin-left: 10px;
}
</style>
