<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">담당자 등록</div>
        <div class="description">
          담당자 정보를 등록합니다. <span class="text_red">(* 필수항목)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">담당자정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체명<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <template v-if="partnerCompanys.length > 0">
                        <button
                          v-for="partnercompany in partnerCompanys"
                          :key="partnercompany.id"
                          type="button"
                          :class="{
                            on: selectedpartnerCompany === partnercompany.id,
                          }"
                          @click="selectPartnerCompany(partnercompany.id)"
                        >
                          {{ partnercompany.companyName }}
                        </button>
                      </template>
                      <!-- 업체가 없을 때 메시지 표시 -->
                      <template v-else> 등록된 업체가 없습니다. </template>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 이름<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="담당자 이름을 입력해주세요"
                      v-model="managerName"
                      ref="managerName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>담당자 직급(직책)<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="담당자 직급(직책)을 입력해주세요"
                      v-model="managerUserPosition"
                      ref="managerUserPosition"
                    />
                  </td>
                </tr>
                <tr>
                  <th>담당자 번호<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td mobile_number">
                      <select v-model="partnerManagerPhoneFirstPart">
                        <option>010</option>
                        <option>011</option>
                        <option>016</option>
                        <option>017</option>
                        <option>018</option>
                        <option>019</option>
                      </select>
                      -
                      <input
                        type="text"
                        class="w_55"
                        maxlength="4"
                        v-model="partnerManagerPhoneSecondPart"
                        ref="partnerManagerPhoneSecondPart"
                      />
                      -
                      <input
                        type="text"
                        class="w_55"
                        maxlength="4"
                        v-model="partnerManagerPhoneThirdPart"
                        ref="partnerManagerPhoneThirdPart"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="특이사항을 입력하세요. "
                        v-model="specialMemo"
                        ref="specialMemo"
                      ></textarea>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveManager">등록</button>
            <button type="button" @click="goToManagerList">취소</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "ManagersAdd",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      partnerCompanys: [], // 파트너 회사 목록을 저장할 배열
      selectedpartnerCompany: "", // 선택된 파트너 회사

      partnerManagerPhoneFirstPart: "010", // 담당자 휴대폰번호 첫 번째 부분
      partnerManagerPhoneSecondPart: "", // 담당자 휴대폰번호 두 번째 부분
      partnerManagerPhoneThirdPart: "", // 담당자 휴대폰번호 세 번째 부분
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
  },
  methods: {
    async fetchPartnerCompanys() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.partnerCompanys = Array.isArray(data.data) ? data.data : [];
          console.log(this.partnerCompanys);
        }
      } catch (error) {
        console.error("Error fetching partner companys:", error);
      }
    },
    selectPartnerCompany(partnercompanyId) {
      this.selectedpartnerCompany = partnercompanyId;
    },
    goToManagerList() {
      this.$router.push({ path: "/managers/list" });
    },
    async saveManager() {
      if (this.checkFields()) {
        try {
          const response = await apiClient.post("/api/Manager/manager-add", {
            CompanyId: this.selectedpartnerCompany,
            UserName: this.managerName,
            UserPosition: this.managerUserPosition,
            UserPhone: `${this.partnerManagerPhoneFirstPart}-${this.partnerManagerPhoneSecondPart}-${this.partnerManagerPhoneThirdPart}`,
            SpecialMemo: this.specialMemo,
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          });
          if (response.status === 200) {
            console.log("담당자 정보가 성공적으로 등록되었습니다.");

            this.toast.success("담당자 정보가 성공적으로 등록되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToManagerList();
          }
        } catch (error) {
          console.error("Error saving manager data:", error);
          this.toast.error("담당자 정보가 등록 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    checkFields() {
      if (!this.selectedpartnerCompany) {
        this.toast.error("업체명를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        return false;
      }
      if (!this.managerName) {
        this.$refs.managerName.focus();
        this.toast.error("담당자 이름을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.managerUserPosition) {
        this.$refs.managerUserPosition.focus();
        this.toast.error("담당자 직급(직책)을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.partnerManagerPhoneSecondPart) {
        this.$refs.partnerManagerPhoneSecondPart.focus();
        this.toast.error("담당자 상세번호(가운데자리)를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.partnerManagerPhoneThirdPart) {
        this.$refs.partnerManagerPhoneThirdPart.focus();
        this.toast.error("담당자 상세번호(마지막자리) 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.fetchPartnerCompanys(); // 소속업체 목록을 가져옴
  },
};
</script>
